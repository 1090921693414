import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { extractor } from '@scheduler-frontend/common/functions';
import { environment } from '@scheduler-frontend/environments';
import { JsonLdInterceptor } from '@techniek-team/common';
import { TtMenuModule } from '@techniek-team/components/menu-layout';
import { TtFetchModule } from '@techniek-team/fetch';
import { AuthModule, LyceoUser, UserService } from '@techniek-team/oauth';
import { TtPermissionModule } from '@techniek-team/permissions';
import { SentryEnvironment, SentryWebModule } from '@techniek-team/sentry-web';
import { setDefaultOptions } from 'date-fns';
import { nl } from 'date-fns/locale';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { MenuLayoutPageModule } from './menu-layout/menu-layout.module';
import { PreFetchService } from './shared/services/pre-fetch/pre-fetch.service';
import { TtFeatureFlagsModule } from './shared/tt-feature-flags/tt-feature-flags.module';

registerLocaleData(localeNl, 'nl');
setDefaultOptions({
  locale: nl,
  weekStartsOn: 1,
});

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot({
      name: '__scheduler-frontend',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    SentryWebModule.forRoot({
      environment: environment.environment,
      dsn: 'https://31bab22356a0415494b67b7b5a5493ef@errors.techniek-team.nl//31',
      release: environment.release,
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      userService: UserService as any,
      autoSessionTracking: false,
      extractor: extractor,
    }),
    AuthModule.forRoot({
      clientId: environment.ssoConfig.clientId,
      ssoBaseUrl: environment.ssoConfig.ssoBaseUrl,
      profileBaseUrl: environment.ssoConfig.profileBaseUrl,
      baseUrl: environment.ssoConfig.baseUrl,
      redirectUri: environment.ssoConfig.redirectUri,
      loginUrl: '/login',
      homeUrl: '/home',
      whitelist: [
        'amazon',
      ],
      model: LyceoUser,
    }),
    TtFetchModule.forRoot({
      baseUrl: environment.scheduler.url,
      preFetchService: PreFetchService,
      debug: environment.debug || environment.environment === SentryEnvironment.LOCAL,
    }),
    TtPermissionModule.forRoot({
      roleHierarchy: {
        ROLE_SKOLEO_TT_PLANNER_ADMIN: [
          'admin',
          'ROLE_SKOLEO_TT_PLANNER_CLUSTER_MANAGEMENT',
          'ROLE_SKOLEO_TT_PLANNER_LOCATION_MANAGEMENT',
          'ROLE_SKOLEO_TT_PLANNER_SCHEDULING_TEAM',
        ],
        ROLE_SKOLEO_TT_PLANNER_CLUSTER_MANAGEMENT: 'clusterManager',
        ROLE_SKOLEO_TT_PLANNER_LOCATION_MANAGEMENT: 'locationManager',
        ROLE_SKOLEO_TT_PLANNER_SCHEDULING_TEAM: 'schedulingTeam',
      },
      // todo remove this cast after upgrade to @techniek-team/common@>=12
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      userService: UserService as any,
    }),
    TtFeatureFlagsModule.forRoot({
      url: environment.gitlabFeatureFlags.url,
      appName: 'skoleo-scheduler-front-end',
    }),
    MenuLayoutPageModule,
    TtMenuModule,
    CoreModule,
    AppRoutingModule,
    HammerModule,
    StoreModule.forRoot({}, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: false,
        strictActionTypeUniqueness: false,
      },
    }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.environment !== SentryEnvironment.LOCAL, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    { provide: PreFetchService },
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
    { provide: HTTP_INTERCEPTORS, useClass: JsonLdInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
